<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div  class="card mb-1">

      <DateQuerySetter :inEnoughSpace="false" btnTitle="Go" @onClickGo="investigationInvoiceList">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-1">
          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="col-form-label">Party: </label>
            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
          </div>
          <AsyncSelect
              placeholder="Select Party"
              v-model="contactProfile"
              :api-service="fetchContactProfiles"
              :additional-query="additionalContactOption"
              :format-label="formatPatientLabel"
              :key="contactRenderKey"
          />
        </div>
      </DateQuerySetter>
    </div>

    <div class="col-12">
      <div class="d-flex justify-content-end px-2">
        <button
            @click="exportTable"
            class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
        >
          Export
        </button>
      </div>
    </div>

    <div class="card mt-1 px-2">
      <ListTable :tableItems="investigations"/>
    </div>

    <div class="px-2">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
    <ContactDropdownFilter
        v-if="$store.state.isModalOpenThree"
        @onSearchContact="onSearchContact"
        type="customer"
    />
    <Loader v-if="reportLoading"/>
  </div>
</template>

<script setup>
import {onMounted, ref, defineEmits, computed, watch, inject} from 'vue';
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleReport from '@/services/modules/inventory';
import handleHospitalReport from "@/services/modules/hospital/report";
import handleContact from "@/services/modules/contact";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import {useRoute, useRouter} from "vue-router";
import ListTable from "@/components/molecule/company/inventory/product-sales-register/CustomerTabTable";
import Pagination from '@/components/atom/Pagination'
import {useStore} from "vuex";
import Loader from "@/components/atom/LoaderComponent.vue";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'


const {fetchHome, reportLoading} = handleReport();
const {fetchInvestigationInvoiceReport} = handleHospitalReport();
const {fetchContactProfiles} = handleContact();
const { formatPatientLabel } = useAsyncDropdownHelper();

const showError = inject('showError');
const showSuccess = inject('showSuccess');

const $router = useRouter()
const $route = useRoute()
const $store = useStore()

let contactProfile = ref(null)
const contactRenderKey = ref(0);

const investigations = ref([]);
const offset = 20;

const companyId = computed(() => $route.params.companyId);
const start = computed(() => $route.query.start);
const end = computed(() => $route.query.end);
const page = computed(() => $route.query.page);
const token = computed(() => localStorage.getItem('token'));

const additionalContactOption = ref({
  with_mobile: 1,
  dealer_id: '',
  roles: 'customer'
});


const exportTable = () => {
  let query = getQuery();
  let url = `${process.env.VUE_APP_BASE_URL}/export/reports/investigation-invoice-report${query}&_token=${token.value}`;
  let a = document.createElement("a");
  a.setAttribute("href", url);
  a.click();
};

const onSearchContact = (contact) => {
  contactProfile.value = contact
}

const getQuery = () => {
  let query = '?company_id=' + companyId.value
  query += '&group_by_customer=' + true
  if(contactProfile.value) query += '&contact_profile_id='+contactProfile.value.id
  if (!start.value && !end.value) return query
  if (start.value) query += '&start_date=' + start.value
  if (end.value) query += '&end_date=' + end.value
  if (page.value) query += '&page=' + page.value
  query += '&offset=' + offset
  return query
};

const onPageChange = async (page) => {
  let routeQuery = Object.assign({}, $route.query)
  routeQuery.page = page
  await $router.push({path: $route.path, query: routeQuery})

  await investigationInvoiceList();
}

const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
};

const onOpenContactSearchModal = () => {
  $store.state.isModalOpenThree = true;
}
const setPaginationData = (data) => {
  $store.commit('setPaginationData', data)
};

const resetPagination = () => {
  $store.commit('resetPagination')
};


async function investigationInvoiceList() {
  reportLoading.value = true;
  try {
    const res = await fetchInvestigationInvoiceReport(getQuery());
    if (!res.status) {
      investigations.value = [];
      return resetPagination()
    }
    const {data, ...paginationData} = res.data;
    investigations.value = data;
    await setPagination(paginationData)
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  } finally {
    reportLoading.value = false;
  }
}

onMounted(async () => {
  reportLoading.value = true;
  try {
    const [ homeRes ] = await Promise.all([
      fetchHome()
    ]);
    location.value = 'all';
  } finally {
    reportLoading.value = false;
  }
  await investigationInvoiceList()
});
</script>


<style scoped>
.head td {
  text-align: right;
}
</style>
